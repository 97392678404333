import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Rancho Rustico Restaurant",
    pageDescription:
      "See how a fresh-focused cantina reaches new customers with streamlined tech.",
    title: "A classic cantina stays current",
    subTitle: `A family-owned restaurant, operating since 2013, keeps pace with ordering and marketing trends to reach new customers and sustain the business.`,
    businessName: "Rancho Rustico Restaurant",
    industry: "Restaurant, casual dining",
    location: "Tucson, AZ",
    products: [
      spotonProducts.RESTAURANT,
      spotonProducts.ORDER,
    ],
    description:
      "José Luis Arceo, owner of Rancho Rustico Restaurant, has the key to operating for over nine years: staying up-to-date on technology. Arceo has worked in every corner of the restaurant industry, but since becoming an owner, he values accessible, detailed reporting that lets him oversee sales trends and make decisions that help his general managers during the weekend crush.",
    quoteData: {
      imgName: "rancho-rustico.png",
      quote: `“SpotOn brought us the technology we need to integrate with third-party platforms, sell gift cards, and see more accurate numbers in our reporting. We can see everything in one place. It has made our servers’ lives easier and my life easier.”`,
      personName: "José Luis Arceo",
      personTitle: "Owner, Rancho Rustico Restaurant",
    },
    goal: `Arceo wanted a POS system that would integrate with third-party platforms and Google search to increase marketing reach and gain new customers. He also needed accurate sales data for labor forecasting and the ability to monitor the restaurant even when offsite.`,
    solution: `While Arceo used to send coupons and place newspaper ads, he now markets his business through social media and Google—encouraging guests to order directly from Google search through SpotOn Order with Google. Integrating with third-party platforms has streamlined back-of-house operations and helped increase sales. He was even able to take a two-week vacation in Mexico with the peace of mind that his restaurant was fully staffed and he could support his managers from afar with insights from the SpotOn Reporting app.`,
    results: {
      title: `The results`,
      stats: [
        { title: `45%`, description: `Decrease in order errors with online ordering` },
        { title: `10%`, description: `Increase in sales` },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: '"Slow" food meets fast tech',
        imageName: "blue-barn.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-06-24",
        href: "/case-studies/blue-barn",
        goals: [
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Opening a barbecue joint with smart tech",
        imageName: "barbwire.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-06-29",
        href: "/case-studies/barbwire-barbecue",
        goals: [
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Selling tacos, turning tables",
        imageName: "la-chiva-loka.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-14",
        href: "/case-studies/la-chiva-loka",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
        ],
    },
  ];
  